import { Link } from "react-router-dom";
import "./Header.css"

function Header() {
    return (
        <div className="navbar">
            <Link to="/">
                <svg className="nav-logo" viewBox="0 0 228 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M74.1845 18.0158H79.0735V40.8294H74.1845V32.7782C73.0435 34.1154 71.4148 34.7658 69.3291 34.7658C67.1789 34.7658 65.3539 33.9528 63.8205 32.2904C62.2871 30.628 61.5386 28.576 61.5386 26.1623C61.5386 23.7514 62.2871 21.7302 63.8205 20.0678C65.3539 18.4054 67.1789 17.5588 69.3291 17.5588C71.4148 17.5588 73.0435 18.2092 74.1845 19.5464V18.0158ZM70.3074 30.1402C71.4484 30.1402 72.3931 29.7814 73.1108 29.0638C73.8284 28.3461 74.1873 27.3677 74.1873 26.1623C74.1873 24.9569 73.8284 23.9785 73.1108 23.2608C72.3931 22.5432 71.4484 22.1844 70.3074 22.1844C69.1665 22.1844 68.2217 22.5432 67.5041 23.2608C66.7864 23.9785 66.4276 24.9569 66.4276 26.1623C66.4276 27.3677 66.7864 28.3461 67.5041 29.0638C68.2217 29.7814 69.1665 30.1402 70.3074 30.1402Z" fill="#1B3D6E"/>
                    <path d="M93.1888 18.0159H98.0778V34.3117H93.1888V32.7811C92.2104 34.1183 90.6462 34.7687 88.5268 34.7687C86.8 34.7687 85.331 34.1828 84.1592 32.9773C83.0183 31.7719 82.4324 30.1403 82.4324 28.0238V18.0159H87.3214V27.3033C87.3214 29.2264 88.496 30.2693 90.1584 30.2693C92.017 30.2693 93.1888 29.1283 93.1888 26.9109V18.0159Z" fill="#1B3D6E"/>
                    <path d="M102.025 15.7339C100.884 14.5593 100.884 12.7343 102.025 11.5934C103.2 10.4188 105.025 10.4188 106.166 11.5934C107.34 12.7343 107.34 14.5593 106.166 15.7339C105.022 16.8749 103.197 16.8749 102.025 15.7339ZM101.666 34.3116V18.0158H106.555V34.3116H101.666Z" fill="#1B3D6E"/>
                    <path d="M111.769 32.2933C110.14 30.631 109.325 28.6097 109.325 26.1652C109.325 23.7207 110.14 21.6995 111.769 20.0371C113.432 18.3747 115.484 17.5618 117.962 17.5618C121.09 17.5618 123.894 19.2241 125.329 21.7331L121.059 24.2085C120.474 23.0031 119.431 22.3835 117.897 22.3835C115.778 22.3835 114.214 23.9478 114.214 26.1624C114.214 28.3799 115.778 29.9441 117.897 29.9441C119.428 29.9441 120.504 29.3246 121.059 28.1192L125.329 30.5637C123.894 33.1399 121.124 34.7687 117.962 34.7687C115.486 34.7687 113.432 33.9557 111.769 32.2933Z" fill="#1B3D6E"/>
                    <path d="M142.931 34.3117H137.389L132.175 27.1071V34.3117H127.286V11.498H132.175V25.1559L137.064 18.0186H142.769L136.837 26.1651L142.931 34.3117Z" fill="#1B3D6E"/>
                    <path d="M167.478 19.0192C168.618 20.2246 169.174 21.8533 169.174 23.9082V33.9133H164.285V24.3315C164.285 22.6691 163.469 21.6571 161.938 21.6571C160.34 21.6571 159.395 22.7981 159.395 24.7212V33.9133H154.506V24.3315C154.506 22.6691 153.691 21.6571 152.16 21.6571C150.562 21.6571 149.617 22.7981 149.617 24.7212V33.9133H144.728V17.6175H149.617V19.1173C150.498 17.8137 151.964 17.1633 153.985 17.1633C155.874 17.1633 157.31 17.8782 158.255 19.3135C159.264 17.8782 160.831 17.1633 162.981 17.1633C164.837 17.1605 166.337 17.7801 167.478 19.0192Z" fill="#0DBAD2"/>
                    <path d="M176.899 27.7208C177.455 29.2515 178.758 30.0028 180.81 30.0028C182.147 30.0028 183.19 29.5795 183.939 28.7637L187.849 31.0148C186.251 33.2631 183.871 34.3704 180.746 34.3704C178.007 34.3704 175.823 33.5574 174.161 31.9259C172.532 30.2971 171.716 28.2423 171.716 25.7641C171.716 23.3196 172.529 21.2675 174.127 19.636C175.756 17.9736 177.841 17.1606 180.384 17.1606C182.764 17.1606 184.718 17.9736 186.285 19.636C187.883 21.2647 188.662 23.3196 188.662 25.7641C188.662 26.4481 188.598 27.1013 188.466 27.7208H176.899ZM183.807 24.0709C183.319 22.344 182.178 21.4946 180.353 21.4946C178.464 21.4946 177.289 22.3412 176.801 24.0709H183.807Z" fill="#0DBAD2"/>
                    <path d="M203.105 11.0969H207.995V33.9105H203.105V32.3799C201.965 33.7171 200.336 34.3675 198.25 34.3675C196.1 34.3675 194.272 33.5545 192.742 31.8921C191.208 30.2297 190.46 28.1777 190.46 25.764C190.46 23.3531 191.208 21.3319 192.742 19.6695C194.272 18.0072 196.097 17.1606 198.25 17.1606C200.336 17.1606 201.965 17.8109 203.105 19.1481V11.0969ZM199.228 29.742C200.369 29.742 201.314 29.3831 202.032 28.6655C202.749 27.9478 203.108 26.9695 203.108 25.764C203.108 24.5586 202.749 23.5802 202.032 22.8626C201.314 22.1449 200.369 21.7861 199.228 21.7861C198.087 21.7861 197.143 22.1449 196.425 22.8626C195.707 23.5802 195.349 24.5586 195.349 25.764C195.349 26.9695 195.707 27.9478 196.425 28.6655C197.143 29.3831 198.087 29.742 199.228 29.742Z" fill="#0DBAD2"/>
                    <path d="M221.848 17.6172H227.062L221.489 33.4252C219.664 38.5413 216.796 40.6607 212.199 40.4336V35.8698C214.447 35.8698 215.524 35.1521 216.272 33.1645L209.785 17.6172H215.165L218.781 27.5915L221.848 17.6172Z" fill="#0DBAD2"/>
                    <path d="M30.2762 0.548096H20.1842H15.1382V5.59411V15.6862V20.7294V20.7322H25.2302V15.6862H20.1842V5.59411H30.2762V25.7698H35.3223V5.59411V0.548096H30.2762Z" fill="#1B3D6E"/>
                    <path d="M45.4165 15.6863H40.3705V20.7323H45.4165V30.8215H30.2784V35.8676H45.4165H50.4625V30.8215V20.7323V15.6863H45.4165Z" fill="#1B3D6E"/>
                    <path d="M30.2762 40.9109V45.9541H20.187V30.8216H20.1814V30.8188H5.04614V20.7296H10.0922V15.6836H5.04614H0.00012207V20.7296V30.8188V35.8649H5.04614H15.141V45.9541V51.0001H20.187H30.2762H35.3223V45.9541V40.9109H30.2762Z" fill="#0DBAD2"/>
                </svg>
            </Link>            
        </div>
    );
}

export default Header;