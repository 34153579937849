import Header from "./Header";
import Footer from "./Footer"
import "./TermsOfUse.css"
import ScrollToTop from "./ScrollToTop";

function PrivacyPolicy() {
    return (
        <>
        <Header/>
        
        <div className="header-1">Quickmedy Privacy Policy</div>
		<div className="terms-content">
            <p>This Privacy Policy was last modified on 28th Dec 2023.</p> 
		    <div className="header-2">WELCOME</div>

		<p>Jivatu Technologies Pvt. Ltd. ("QuickMedy", "we", "our", or "us") is committed to protecting and respecting your privacy.</p>

        <p>Jivatu Technologies operates a service that allows you to speak to and share your location with other users in your family or friendship group (each group, a "Circle"), which we make available through our apps and our website https://www.quickmedy.com (the "Service").</p>

        <p>This Privacy Policy explains our practices regarding the collection, use, disclosure, transfer, storage, and protection of personal information that is collected through our Service, as well as your choices regarding the collection and use of information as per India laws.</p> 

        <p>Our Service may available in many countries, which may have different standards and requirements in respect of the information we provide in the Privacy Policy and how we use personal information.</p>

        <p>Before accessing or using our Service, please ensure that you have read and understood how we collect, store, use and disclose your personal information as described in the part of this Privacy Policy that is relevant to you.</p>

        <p>This Part 1 of our Privacy Policy explains how we collect, use, disclose and protect information that is collected through our Service from users in India </p>

        <div className="header-2">1. HOW WE COLLECT AND USE INFORMATION</div>

        <p>Capitalized terms that are not defined in this Privacy Policy have the meaning given to them in our Terms of Use.</p>

        <p>We collect the following types of information about you:</p>

        <p>Registration and Profile Information. When you create an account, we may collect your personal information such as your username, first and last name, email address, mobile phone number. We may collect a photo, if you or a Circle member associate a photo with your account. If you want to add a family member’s name to your account, we collect the family member’s name, email address, and mobile phone number. We may also collect billing information when you sign up for Subscription services or paid services.</p>

        <p>Data collected through use of the Service. After you set up your account, we may ask for other profile and demographic information, such as personal emergency instructions and information, and nickname. You may also customize your map by labeling locations, such as “work” and “home,” to make it easy to spot your favorite places and to provide alerts when family members leave and arrive.,/p</p>

        <div className="sub-header">Geolocation.</div> <p>We collect your location information through your IP address, WiFi, Bluetooth, and GPS coordinates (e.g., latitude/longitude) available through your mobile device. We may also gather information about your location using other technologies and sensors which may provide us with information about nearby devices, WiFi access points and networks, and nearby cell towers. If you enable the mobile app to use your Bluetooth signal, we may be able to determine location based on nearby Bluetooth-enabled devices, which helps us to improve location accuracy, identify other QuickMedy users and devices that have been in proximity to each other, and improve location updates for all devices in the QuickMedy network. Depending on your device settings, we collect location information in the background, even when you are not actively using the app. We collect and maintain location data to provide you the features and functionality of the Service. We may also share location information with our partners, and its Partners, for tailored advertising, attribution, analytics, research and other purposes, as described in Section 2 “Sharing of Your Information” below. If you want to opt-out of the collection of your location data, please adjust your settings in your mobile device to limit the app’s access to your location data. Please see “Your Choices About Your Information” below to learn more.</p>

        <div className="sub-header">Driving Event Data</div><p>We collect, and may use a third party partner and/or software to collect, sensory and motion data from your smartphone or mobile device, including information from the gyroscope, accelerometer, compass and Bluetooth, in order to calculate and detect driving events such as speeding, hard braking, crash detection and other events. Your mobile device provider may refer to this information as “Motion and Fitness” data (description may vary according to device and platform). We may also share this information with third party partners for marketing, research, analytics and other purposes. If you want to opt-out of the collection of your sensory and motion, please adjust your settings in your mobile device to limit the app’s access to your sensory and motion data. Please see “Your Choices About Your Information” below to learn more.</p>

        <div className="sub-header">Messages and User Content.</div><p> We collect information when you message other members of your Circle through use of the service. We may also retain any messages you send to us.</p>

        <p>We use this information to operate, maintain, and provide to you the features and functionality of the Service and to communicate directly with you, such as to send you alerts on the movements of your Circle, crime alerts in your neighborhood, etc.</p>

        <div className="sub-header">Social Media.</div><p> When you interact with our site through various social media, such as when you Like us on Facebook or post a comment to our Facebook page, we may receive information from the social network such as your profile information, profile picture, gender, user name, user ID associated with your social media account, age range, language, country, friends list, and any other information you permit the social network to share with third parties. The data we receive is dependent upon your privacy settings with the social network. You should always review, and if necessary, adjust your privacy settings on third-party websites and services before linking or connecting them to QuickMedy website or Service.</p>

        <div className="sub-header">Identity verification.</div>
            <p>In limited circumstances, we may use identity verification services provided by a third-party provider to verify your
            age and/or to obtain verifiable parental consent for your child to use the Service. When identity verification is
            required, we will ask you to upload an image of your valid government-issued ID along with a selfie image.
            Our identify verification provider will use a combination of machine learning tools and optical scans to 
            authenticate your identity document, and may use facial recognition technology to produce a unique biometric
            identifier based on facial geometry that can be used to compare your selfie to the image on the identity 
            document you provided to determine the likelihood that the images are a "match." QuickMedy not receive the
            biometric identifier generated from the images. It is generated and held by our identity verification provider
            until the biometric identifier is no longer needed for the purposes described in this paragraph, which will
            occur no later than 60 days after your last verification interaction with us.   For identity verification 
            and security purposes, QuickMedy may have access to the information extracted from the identity scan of the
            ID document (such as a name and partial driver's license number) as well as the results of the identity
            verification process. We may use this process and associated information to verify your identity, 
            authenticate your identity documents, confirm appropriate parental consent, and protect against fraud 
            and misrepresentation.  We do not use, disclose or retain your biometric information for any other
            commercial purpose. Our third-party provider processes this information on our behalf strictly in 
            accordance with our contractual agreements.Please note this service may not be available in all jurisdictions.</p>

        <div className="sub-header">Information from third-party services.</div>
            <p> We collect information from third party services that you connect to your QuickMedy account. For example, if you
                 link your QuickMedy account to a voice assistant platform to enable interaction with our service through voice commands,
                 such as through the QuickMedy Google Action (“Action”) available on Google Assistant-enabled devices, we will collect the
                 queries and commands applicable to QuickMedy that you make through the Action or other voice assistant platforms.</p>
            <p>Information we collect automatically through the use of technology. When you visit our website, use our mobile app,
                 read our emails or otherwise engage with us, we and our business partners may automatically collect certain 
                 information about your computer or device through a variety of tracking technologies, including cookies, web
                  beacons, log files, embedded scripts, location-identifying technologies, or other tracking/recording tools
                   (collectively, “tracking technologies”), and we may combine this information with other personal information
                    we collect about you. We use these tracking technologies to collect usage and device information, such as:</p>
            <p>Information about how you access the Service, for example, referral/exit pages, how frequently you access the Service, whether you open emails or click the links contained in emails, whether you access the Service from multiple devices, and other actions you take on the Service.
            Information about how you use the Service, for example, the features you use, the links you click, the ads you view and click on, purchase transaction information, your location when you access or interact with our Service, and other similar actions.</p>
            <p>Information about the computer, tablet, smartphone or other device you use, such as your IP address, browser type, Internet service provider, platform type, device type/ model/ manufacturer, operating system, phone number, mobile carrier, date and time stamp, a unique ID that allows us to uniquely identify your browser, mobile device or your account (including, for example, a UDID, IDFA, Google Ad ID, Windows Advertising ID or other persistent device identifier or Ad ID), battery life, and other such information. With your permission, we may also access your photo or camera roll.</p>
            <p>We may collect analytics data, or use third-party analytics tools such as Google Analytics,India Map to help us measure traffic and usage trends for the Service and to understand more about the demographics of our users. You can learn more about Google's practices at http://www.google.com/policies/privacy/partners, and view its currently available opt-out options at https://tools.google.com/dlpage/gaoptout.</p>
            <p>We use this information to (i) remember information so that you will not have to re-enter it during your visit or the next time you visit the Service; (ii) provide custom, personalized content and information, including targeted content and advertising; (iii) track your location and movements; (iv) identify and contact you across multiple devices; (v) provide and monitor the effectiveness of our Service and our marketing campaigns; (vi) monitor aggregate metrics such as total number of visitors, users, traffic, and demographic patterns; (vii) diagnose or fix technology problems reported by our Users or engineers that are associated with certain IP addresses or User IDs; and (viii) detect or prevent fraud or other harmful activities; (xi) automatically update our mobile application on your system and related devices; and (x) otherwise plan for and enhance our Service.</p>

            <p>If you prefer not to accept cookies, most browsers will allow you to: (i) change your browser settings to notify you when you receive a cookie, which lets you choose whether or not to accept it; (ii) disable existing cookies; or (iii) set your browser to automatically reject cookies.  Please note that doing so may negatively impact your experience using the Service, as some features and services on our Service may not work properly. Depending on your mobile device and operating system, you may not be able to delete or block all cookies. These browser settings that block cookies may have no effect on non-cookie technologies. You may also set your e-mail options to prevent the automatic downloading of images that may contain technologies that would allow us to know whether you have accessed our e-mail and performed certain functions with it. Although we do our best to honor the privacy preferences of our Users, we are not able to respond to Do Not Track signals from your browser at this time. </p>

            <p>We and our third party partners may also use information collected through tracking technologies for advertising purposes. Please see “Third Party Tracking and Online Advertising” below to learn more.</p>

        <div className="header-2">2. SHARING OF YOUR INFORMATION</div>

        <div className="sub-header">Personal Information:</div><p>  We share your personal information with third parties outside the Company and its controlled subsidiaries and affiliates in some circumstances, as follows:</p>

        <p>We share your personal information, including name, email, precise location and driving event data, with others within your QuickMedy Family and other QuickMedy circles. Depending on the features and functionality enabled, we may also share other information about your use of the Service with members of your Family Circle, such as whether you have logged in on a new device.</p>
        <p>We may share your personal information, including your precise location data, driving sensor data, unique identifiers or AD IDs and other data with our partners and its Partners, for their marketing and business purposes, including without limitation, to inform and improve tailored advertising, attribution, analytics or research purposes. These third party partners may use this information according to their own privacy policies. Please see “Your Choices About Your Information” to learn how you may be able to limit some third-party data sharing.</p>

        <p>We share your personal information with the third party  (“Third Party”), which provides driving analytics behavior services to enable us to provide certain functionalities of the Service, such as driving event history and crash detection. We may share geolocation and movement data, mobile device information (such as information generated by the gyroscope and accelerometer in your device) and application analytics, including IP address and device identifiers, driving event data and information provided by you during your registration of a QuickMedy account or as part of your use of our Services. Arity uses this information to analyze your driving behaviors, such as speed, change in speed and other aspects of how, how much, where and when you drive in order to predict driving risk and calculate a driving risk score. Third Party  also uses this information to improve its analytics and predictive driving behavior models and improving its service generally.</p>

        <p>For India users only, Third Party may use this information to provide personalized content including targeted advertising, offer discounts, rewards or pricing offers by third parties such as insurance companies, and for other commercial purposes. Please see “Your Choices About Your Information” to learn how you may be able to limit some of this data sharing with Third Party. We share personal information with third-party partners that provide certain features and services you may use in connection with our Service. For example, depending on the features of the Service you have enabled, we provide your personal information and location data to emergency responders in the event we detect a crash. We also share personal information with third party partners that provide services and features available through our paid or subscriptions, such as Emergency Dispatch services. The third parties that provide Premium service features may ask you for additional information in order to provide the full range of services to you. Any information provided to these third parties will be governed by that third party’s own privacy policy.</p>

        <p>We share personal information with third parties that you have connected to your QuickMedy account to facilitate the features or services. For example, if you enable the QuickMedy Action on any Google Assistant-enabled device or use other voice-assistant services, QuickMedy will respond to any query received through the Action and disclose your personal information we well as the personal information (such as name, current location, and other data) of your Circle members through screen display or audio response. Google and the equipment the Google Assistant is installed on, may record your interactions with the Action and the response data in accordance with your agreements with Google. You are solely responsible for the use of the QuickMedy Action to access information about your Circle members on your Assistant-enabled devices, including in the case of any unauthorized access to your device by a third party, and for the consequences of enabling the Action on any of your devices.</p>

        <p>Other users may share your personal information in certain circumstances. For example, if a member of your Circle enables the QuickMedy Action on a Google Assistant-enabled device, your personal information (including name, current location and other data) may be displayed on screen or via audio response on any device with the QuickMedy Action enabled. This means your information may be available to other individuals who have access to your Circle member’s Assistant-enabled device or who may overhear your Circle member interacting with the Action. To prohibit this data sharing, you must ask your Circle member to disconnect his or her account from the QuickMedy Action or you can leave the Circle.</p>

        <p>We use the Captcha anti-bot service on our website to check whether the data entered on our website has been entered by a human or by an automated program. To do this, Captcha analyzes the behavior of the website or mobile app visitor based on various characteristics (e.g. IP address, how long the visitor has been on the website or app, or mouse movements made by the user). This analysis starts automatically as soon as the website or mobile app visitor enters a part of the website or app with Captcha enabled and Captcha may take place completely in the background in “invisible mode.” </p>

        <p>Any information or content that you voluntarily post to the website or to our Facebook page will automatically be available to the public. We may publicly post your reviews and comments online on our website or mobile app or link your reviews to third party websites.</p>

        <p>Other instances in which we may share your information: We may also share your information for business purposes, as follows:</p>

        <p>We may share your personal information with our third-party business partners, vendors and consultants who perform services on our behalf or who help us provide our Services, such as accounting, managerial, technical, marketing or analytic services, and to other third-party partners or service providers who contractually agree to process personal information solely as necessary to provide services or features to our users.</p>

        <p>We may at any time buy or sell/divest the company, or any combination of its products, services, assets and/or businesses. Personal information such as customer names and email addresses, and other user information related to the Service will likely be one of the items transferred in these types of transactions. We may also share, transfer or assign such information in the course of corporate divestitures, mergers, acquisitions, bankruptcies, dissolutions or similar transactions or proceedings.</p>

        <p>We may disclose personal information to third parties (including law enforcement, other government entity, or civil litigant; excluding our subcontractors) when required to do so by law or subpoena or if in our sole discretion, we determine it is necessary to (a) conform to the law, comply with legal process, or investigate, prevent, or take action regarding suspected or actual illegal activities; (b) to enforce our Terms of Use, take precautions against liability, to investigate and defend ourselves against any claims or allegations, or to protect the security or integrity of our site; and/or (c) to exercise or protect the rights, property, or personal safety of QuickMedy, our Users or others.</p>

        <p>QuickMedy is India-based organization and we store your personal information in India, which may be outside your jurisdiction of residence. Our partners and service providers may also store your personal information outside your jurisdiction of residence. Accordingly, your personal information will be accessible to law enforcement and/or regulatory authorities according to the applicable laws of such jurisdictions.</p>


        <div className="header-2">3.  HOW WE PROTECT YOUR INFORMATION</div>

        <p>QuickMedy cares about the security of your information and uses commercially reasonable physical, administrative, and technological safeguards to preserve the integrity and security of all information we collect and that we share with our service providers. However, no security system is impenetrable and we cannot guarantee the security of our systems 100%. In the event that any information under our control is compromised as a result of a breach of security, we will take reasonable steps to investigate the situation and where appropriate, notify those individuals whose information may have been compromised and take other steps, in accordance with any applicable laws and regulations.</p>


        <div className="header-2">4.  YOUR CHOICES ABOUT YOUR INFORMATION</div>

        <p>You may, of course, decline to submit any personal information through the Service, in which case QuickMedy may not be able to provide its Services to you.</p>

        <p>Modifying Your Account Information and Settings: You may modify your account information, update or amend your personal information, or change your password at any time by logging in to your account. If you have any questions about reviewing or modifying your account information, you can contact us directly at privacy@jivatutech.com.</p>

        <p>Close Your Account or Delete Personal Information: We retain your information for as long as your account is active or as reasonably necessary to maintain the Service, meet legal and accounting obligations, and for the other purposes described in this Privacy Policy. If you wish to close your account, and/or request to delete your personal information, please contact us at privacy@jivatutech.com. When account information is deleted or de-identified, certain residual information may remain within our archive records, such as for customer and technical support, billing and tax purposes.</p>

        <div className="sub-header">Emails and Notifications:</div><p>You can control your receipt of email and text alerts by visiting “Settings” and “Alert Options” within the mobile app. You can also stop receiving promotional email communications from us by clicking on the “unsubscribe” link provided in such communications. You may not opt-out of Service-related emails (e.g., account verification, parental consent notification, order confirmations, change or updates to features of the Service, technical and security notices).  You may also unsubscribe from receiving SMS text messages by replying “STOP” to the number sending the message. We make every effort to promptly process all unsubscribe requests. </p>

        <div className="sub-header">Location and Movement Data Collection and Sharing:</div><p> As described in Section 2, we collect precise location data and driving event and movement data (such as accelerometer data, magnetometer data, gyro data, etc.) from your device to provide the QuickMedy Services, and may share your personal information, including location and movement data, with other QuickMedy users in your Family Circle or other Circle you belong to, and with third parties for crash detection, research, analytics, attribution and tailored advertising purposes. You have many options to control this data collection and sharing activity through the App Settings:</p>

        <p>Limit location sharing with your Circle. You may turn location sharing “OFF” for one or more of your Circles through your App settings.  If you turn off location sharing in one or more of your circles, we will not share your location with such Circles; however, we will continue to collect your location information in order to provide the Service (for example, to provide our emergency services, to share your location with the other Circles, or to provide location-based advertising). Note: if you press “Check In” or “Automated SOS” in the App, your location will update in every one of your Circles even if location sharing is turned off for one or more of your Circles.</p>

        <p>Limit Drive Detection. You can limit the collection and display of location and movement data through the Drive Detection feature in the in-App Privacy settings. When you turn Drive Detection “off,” your location is not visible to your Circles and your location and drive history will not show on Individual or Family Driver Reports. Please note that your QuickMedy app may not be able to provide all of our Services to you turn off Drive Detection. When Drive Detection is turned “off,” Crash Detection services will be disabled and safety services like SOS Help Alerts and 24/7 Dispatchers will not be available.</p>  

        <p>Limit data sharing with third party data partners through in-App Privacy Settings. To opt-out of QuickMedy sharing personal information (including location and movement data) with data partners for targeted advertising, research, attribution and similar purposes, you can opt-out through the Do Not Sell My Personal Information page in the in-App Privacy & Security settings. If you opt-out, QuickMedy will continue to collect your device location and movement data and to share such data with your Circles and with third parties as needed to support and provide the QuickMedy services or other services you engage, but we will no longer share data about you with our third party data partners for their own advertising and other commercial purposes. Please note, some third party data partners may continue to send you location-based ads based on your previous location or may approximate your location based on other data.</p>

        <p>Limit driving event sharing with our partners. We share data with our partner to support important App features like driving behaviour and Crash Detection. Our partners also uses data for research, to improve its analytics and predictive driving behavior models and improve its performance generally. To opt-out of permitting our partners to use and learn from your data for its own research and to develop and improve its predictive behavior models and products and services generally, you can opt-out through the Driving Services page in the in-App Privacy Settings. To opt-out of permitting Thitd Party to use data for its own commercial purposes, including marketing, advertising, and for India users only to provide and service insurance company products and ads, you can opt-out through the Do Not Sell My Personal Information page in the in-App Privacy & Security Settings.</p>

        <p>Disconnect Location Data and Movement Data in Device Settings. You may also be able to turn off device location data collection through your mobile device settings.  For example, depending on your mobile device, you may be able to “Turn location services ‘off,’ which restricts access to your device location data. You can also adjust your device settings to withdraw permission for the QuickMedy app to access device location data and/or motion data (depending on the device type, this may be called “Fitness and Motion” data), and you can also adjust your device settings to limit the app’s access to WiFi and Bluetooth information. Please note that your QuickMedy App may not be able to provide all of our Services to you if you disable access to location and movement data through your device settings.</p>
        

        <div className="sub-header">Third Party Tracking and Online Advertising:</div><p> We may share information about your use of our Services over time, including location information, with third party ad networks, social media companies and other third parties so that they may play or display ads that may be relevant to your interests on our Service as well as on other websites, apps or services, or on other devices or advertising channels. We may also display targeted advertising to you through social media platforms, such as Facebook, Twitter, Google+ and others. These third party ad partners collect and use information such as click stream information, timestamp, hashed email address, device ID or AdID, your use of third party applications and/or precise geolocation data and other information, and may combine this information with information they collect directly through tracking technologies or that they receive other partners, both online and offline, so that they may recognize you across other browsers or devices you use, including computers, mobile devices and Smart TVs. These third parties use this information to make the advertisements you see online more relevant to your interests, as well as to provide advertising-related services such as reporting, attribution, analytics, and market research.</p>

        <p>To opt-out or control third-party tracking and advertising: Interest-based advertising. To learn about interest-based advertising and how you may be able to opt-out of the use of your personal information to inform and display targeted advertising, You may also be able to limit interest-based advertising through the settings on your mobile device by selecting “limit ad tracking” (iOS) or “opt-out of interest based ads” (Android).  You may also be able to opt-out of some - but not all - interest-based ads served by mobile ad networks</p>

        <p>Cross-device linking. Please note that opting-out of receiving interest-based advertising will only opt-out a user from receiving interest-based ads on that specific browser or device, but the user may still receive interest-based ads on his or her other devices. You must perform the opt-out on each browser or device you use.</p>

        <p>Some of these opt-outs may not be effective unless your browser is set to accept cookies. If you delete cookies, change your browser settings, switch browsers or computers, or use another operating system, you will need to opt-out again.</p>

        <p>Google Analytics and Advertising. We may also utilize certain forms of display advertising and other advanced features through Google Analytics, such as Remarketing with Google Analytics, Google Display Network Impression Reporting, the DoubleClick Campaign Manager Integration, and Google Analytics Demographics and Interest Reporting. These features enable us to use first-party cookies (such as the Google Analytics cookie) and third-party cookies (such as the Doubleclick advertising cookie) or other third party cookies together to inform, optimize, and display ads based on your past visits to the Service. You may control your advertising preferences or opt-out of certain Google advertising products by visiting the Google Ads Preferences Manager, currently available at https://google.com/ads/preferences </p>

        <p>To opt-out of QuickMedy sharing data with third parties for advertising purposes. To opt-out of QuickMedy sharing personal information (including location and movement data) with data partners for their own targeted advertising, research, attribution and similar purposes, you can opt-out through the Do Not Sell My Personal Information page in the in-App Privacy & Security settings. Please note, some third party data partners may continue to send you personalized or location-based ads based on your previous location or data they have collected from other websites and mobile apps.</p>
        <p>You may request access to, or correction of, your personal information by contacting us at privacy@jivatutech.com.</p>

        <div className="header-2">5.  CHILDREN'S PRIVACY POLICY</div>

        <p>QuickMedy takes special precaution to protect the privacy of our younger users.  QuickMedy does not knowingly collect personal information from children under the age of 18 (a “child”) without verifiable consent of a parent or guardian and the Service is intended to be used by children under 18 only with significant parental involvement and approval.</p>

        <p>If QuickMedy learns that we have inadvertently collected personal information of a child without parental consent, we will take appropriate steps to delete this information. If you are a parent or guardian and discover that your child under the age of 18 has a registered account with our Service without your consent, please alert us immediately by emailing privacy@jivatutech.com so that we may delete that child's personal information from our systems.</p>

        <p>By enabling your child to create a user account and permitting your child to use the Service, you expressly agree to the practices described in this Children's Privacy Policy and you consent to the collection, use, and disclosure of your child's personal information as described herein.</p>

        <div className="sub-header">Setting up a child account.</div><p>If a parent wishes to add a user who is under 18 to the Service, we request the Parent provide verifiable consent through one of several methods, such as by verifying the Parent’s driver’s license or by submitting a parental consent form (available here) via mail or email.  The child will then be able to participate in most aspects of the QuickMedy Service.</p>

        <div className="sub-header">Personal information we collect.</div><p>We take special precautions to collect only as much information as is reasonably necessary for the child to use the Service. As further described in our Privacy Policy above, we collect various types of personal information from all users of the Service. For example, we collect profile information (username, email address and mobile phone number) when a user creates an account and other types of personal information the user may provide during the course of using the Service, such as a nickname, favorite location, and emergency contact information.  We collect information about a user’s device (e.g., IP address, device type/model/manufacture / operating system, and a unique ID that allows us to identify the browser, mobile device or account, such as a UDID, IDFA, Google AdID or similar)  as well as certain usage information about the use of the Service (for example, whether the user opens emails) and we collect analytics data. We collect geolocation data through the device IP address, WiFi, Bluetooth and GPS coordinates available through the user’s mobile device and through technologies and sensors that may be nearby. We also collect driving event data through sensory and motion data sensors in the user’s mobile device (e.g., data from a smartphone’s gyroscope, accelerometer, compass and Bluetooth) in order to calculate and detect driving events such as hard braking, speeding and crash detection. Please review the section of our Privacy Policy called “How We Collect and Use Information” to learn more.</p>

        <div className="sub-header">Information sharing.</div><p>QuickMedy may share or disclose a child's personal information our third party vendors, service providers and business partners as described in our full privacy policy, including, for example, with third parties as needed to analyze and detect driving behavior data, perform analytics or otherwise , support the features and functionality of our Service such as crash detection. However, we do not share or sell personal information collected from the accounts of children under 18 for marketing or advertising purposes, nor permit our third party partners to collect and use such information for advertising purposes. Please review the section of our Privacy Policy called “Sharing of Your Information” to learn more about how we share information with our vendors, service providers and others.</p>

        <div className="sub-header">Sharing information with other QuickMedy users.</div><p>The child's profile information and location will be shared with other members of the Family Circle and any other Circle the child joins. Please note that if your child joins any other Circles, the child will be able to share his or her location with other members of that Circle and may exchange messages with those Circle members. You are responsible for monitoring your child’s account and location-sharing activity through the Service and for blocking or permitting location sharing features.</p>

        <div className="sub-header">Contact Us. </div><p> The Parent has a right to request to review or have deleted the child’s personal information and may withdraw consent for the Service to collect any further personal information from the child. Please contact us at privacy@jivatutech.com to request to review and/or delete any personal information we have collected from the child, or to withdraw permission for QuickMedy to collect any additional information from the child. Please also review the section of the Privacy Policy called “Your Choices About Your Information” to learn more about how you may control or turn off certain data collection and sharing features of the Service.</p>

        <div className="header-2">6.  LINKS TO OTHER WEB SITES AND SERVICES</div>

        <p>We are not responsible for the practices employed by websites or services linked to or from the Service, nor the information or content contained therein. Please remember that when you use a link to go from the Service to another website, our Privacy Policy does not apply to third-party websites or services. Your browsing and interaction on any third-party website or service, including those that have a link on our website, are subject to that third party’s own rules and policies. Please read over those rules and policies before proceeding.</p>

        <div className="header-2">7.  CHANGES TO OUR PRIVACY POLICY</div>

        <p>QuickMedy may, in its sole discretion, modify or update this Privacy Policy from time to time, and so you should review this page periodically. When we change the policy, we will update the ‘last modified’ date at the top of this page. If there are material changes to this Privacy Policy or in how QuickMedy will use your personal information, we will notify you either by prominently posting a notice of such changes prior to implementing the change or by directly sending you a notification.</p>

        <div className="header-2">8.  CONSENT</div>

        <div className="sub-header-inline">
            <div className="header-3">8.1</div>
            <p>We are not required to obtain your consent for most of the processing activities that we undertake in respect of your personal information.</p>
        </div>
        <div className="sub-header-inline">
            <div className="header-3">8.2 </div>
            <p>We may, however, need your consent for certain processing activities. If we need your consent, we will notify you of the personal information we intend to use and how we intend to use it.</p>
        </div>
        <div className="sub-header-inline">
            <div className="header-3">8.3 </div>
            <p>If you have provided consent for certain processing of your personal information, you have the right to withdraw your consent. If you withdraw your consent, this will not affect the lawfulness of our processing of your personal information before your withdrawal.</p>
        </div>

        <div className="sub-header-inline">
            <div className="header-3">8.4 </div>
            <p>Where we share your personal information with your consent with third parties for marketing and related purposes, you may withdraw consent at any time via our in-app Privacy Centre.</p>
        </div>

        <div className="header-2">9.  COOKIES AND SIMILAR TECHNOLOGIES</div>
        <div className="sub-header-inline">
            <div className="header-3">9.1</div>
            <p>Our websites use cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website or access any of our other websites and also allows us to improve our websites.</p>
        </div>
        <div className="sub-header-inline">
            <div className="header-3">9.2</div>
            <p>Please see our for more information about the cookies we use on our website, and how to manage cookies.</p>
        </div>

        <div className="sub-header-inline">
            <div className="header-3">9.3</div>
            <p>Deleting or blocking cookies may not be effective for all types of tracking technologies, such as Local Storage Objects (LSOs) like HTML5.</p>
        </div>
        <div className="header-2">10. CHANGES TO THIS POLICY</div>
        <p>We may update this privacy policy from time to time and so you should review this page periodically. When we change this privacy policy in a material way, we will update the "last modified" date at the end of this privacy policy. Changes to this privacy policy are effective when they are posted on this page.</p>

        <div className="header-2">11.  NOTICE TO YOU</div>

        <p>If we need to provide you with information about something, whether for legal, marketing or other business related purposes, we will select what we believe is the best way to get in contact with you. We will usually do this through email or by placing a notice on our Service. The fact that we may send notices to you will not stop you from being able to opt out of certain types of contact as described in this privacy policy.</p>

        <div className="header-2">12.  CONTACTING US</div>

        <div className="sub-header-inline">
            <div className="header-3">12.1 </div><p>Please contact privacy@jivatutech.com if you have any questions, comments and requests regarding this Privacy Policy.</p>
        </div>
        </div>
        <Footer></Footer>
        <ScrollToTop/>

        </>
    );
} 



export default PrivacyPolicy;


