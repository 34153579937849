
import "./Footer.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareYoutube, faSquareFacebook, faSquareXTwitter, faSquareInstagram, faGooglePlay } from '@fortawesome/free-brands-svg-icons'
import { Link } from "react-router-dom";

function Footer() {
    return (
    <div className="footer">
    <div className="foot-panel1">
            <div className="play-store">
                <FontAwesomeIcon className="play-store-icon" icon={faGooglePlay} />
                <div className="play-store-text">Google Play</div>
            </div>
        </div>
        <div className="foot-panel2">
            <ul>
                <p>Company</p>
                <Link to="/">Careers</Link>
                <Link to="/">Blogs</Link>
            </ul>
            <ul>
                <p>Legal</p>
                <Link to="/privacy-policy">Privacy Policy</Link>
                <Link to="/terms-of-use">Terms of Use</Link>
            </ul>
            <ul>  
                <p>Connect with us</p>
                    <div className="media-link">
                        <FontAwesomeIcon className="brand-icon" icon={faSquareFacebook} />
                        <FontAwesomeIcon className="brand-icon" icon={faSquareXTwitter} />
                        <FontAwesomeIcon className="brand-icon" icon={faSquareInstagram} />
                        <FontAwesomeIcon className="brand-icon" icon={faSquareYoutube} />
                    </div>
            </ul>
        </div>
        <div className="foot-panel3">
            Copyright © Jivatu Technologies Pvt. Ltd. 2023. All Rights Reserved.
        </div>
    </div>
    );
}


export default Footer;