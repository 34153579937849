import React from 'react'

import { Routes, Route } from "react-router-dom";
import Partner from './components/Partner';
import Home from './components/Home';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfUse from './components/TermsOfUse';
import Blogs from './components/Blogs';
import Career from './components/Career';

const App = () => {
  return (
    <Routes>
        <Route index element={<Home />} />
        <Route path="partner" element={<Partner />} />
        <Route path="privacy-policy" element={<PrivacyPolicy/>} />
        <Route path="terms-of-use" element={<TermsOfUse/>} />
        <Route path="blogs" element={<Blogs/>} />
        <Route path="career" element={<Career/>} />
    </Routes>
  )
}

export default App