import "./Home.css";
import Footer from "./Footer";
import Header from "./Header";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGooglePlay } from '@fortawesome/free-brands-svg-icons'

function Home() {
  return (
    <>
      <Header />
      <div className="content">
        <div className="description-box">
            <div className="description">
            <p className="description-heading">
                You begin saving the world by saving one person at a time
            </p>
            <p className="description-para">
                Quickmedy® is a AI powered ecosystem of services that delivers
                life-saving, time critical information to nearest medical help
                during any crash.
            </p>
            <div className="play-store">
                <FontAwesomeIcon className="play-store-icon" icon={faGooglePlay} />
                <div className="play-store-text">Google Play</div>
            </div>
            </div>
        </div>
        <div className="app1">
          <div className="app">
            <div className="app-logo"></div>
          </div>
        </div>
      </div>
      <div className="video">
        <div className="video-border"></div>
        <div className="youtube-panel">
          {/* <iframe
            className="youtube-video"
            src="https://www.youtube.com/embed/tgbNymZ7vqY?playlist=tgbNymZ7vqY&loop=1"
          ></iframe> */}
        </div>
        <div className="video-border-2"></div>
      </div>
      <div className="journey-header">Our Journey</div>
      <div className="journey-description-box">
        <div className="journey-description">
            <div className="journey-dir-a">
            <div
                className="journey-direction"
                style={{ backgroundImage: "url('../images/journey1.png')" }}
            ></div>
            </div>
            <div className="journey-dir-b">
            <div
                className="journey-direction"
                style={{ backgroundImage: "url('../images/journey2.png')" }}
            ></div>
            </div>
            <div className="journey-step">
            <div className="box">
                <div
                className="box-image"
                style={{ backgroundImage: "url('../images/frame-1.svg')" }}
                ></div>
            </div>
            <p>AI/ML based auto detection of any vehicle accident</p>
            </div>
            <div className="journey-dir-a">
            <div
                className="journey-direction"
                style={{ backgroundImage: "url('../images/journey3.png')" }}
            ></div>
            </div>
            <div className="journey-step">
            <div className="box">
                <div
                className="box-image"
                style={{ backgroundImage: "url('../images/frame-2.svg')" }}
                ></div>
            </div>
            <p>
                Notify to close user group and, share location and emergency help
                update at real time.
            </p>
            </div>
            <div className="journey-dir-b">
            <div
                className="journey-direction"
                style={{ backgroundImage: "url('../images/journey4.png')" }}
            ></div>
            </div>
            <div className="journey-step">
            <div className="box">
                <div
                className="box-image"
                style={{ backgroundImage: "url('../images/frame-3.svg')" }}
                ></div>
            </div>
            <p>Automatically book the nearest ambulance.</p>
            </div>
            <div className="journey-dir-a">
            <div
                className="journey-direction"
                style={{ backgroundImage: "url('../images/journey5.png')" }}
            ></div>
            </div>
            <div className="journey-step">
            <div className="box">
                <div
                className="box-image"
                style={{ backgroundImage: "url('../images/frame-4.svg')" }}
                ></div>
            </div>
            <p>Share health data to nearby hospital in real time</p>
            </div>
            <div className="journey-dir-b">
            <div
                className="journey-direction"
                style={{ backgroundImage: "url('../images/journey6.png')" }}
            ></div>
            </div>
        </div>
      </div>
      
      <div className="overview">
        <div className="overview-heading">Product Overview</div>
      </div>
        <div className="overview-content">
            <div className="overviw-image-box">
            <div className="overviw-image"></div>
            </div>
            <div className="overview-description">
            <ul className="overview-point">
                <div
                className="overview-image"
                style={{
                    backgroundImage: "url('../images/ic_discover_small.png')",
                }}
                ></div>
                <div className="overview-box">
                <div className="overview-subpoint">Crash detection</div>
                <p>Automatic ambulance booking in Accident</p>
                </div>
            </ul>
            <ul className="overview-point">
                <div
                className="overview-image"
                style={{ backgroundImage: "url('../images/ic_book_small.png')" }}
                ></div>
                <div className="overview-box">
                <div className="overview-subpoint">Book</div>
                <p>Book an ambulance with quickmedy anytime, anywhere.</p>
                </div>
            </ul>
            <ul className="overview-point">
                <div
                className="overview-image"
                style={{ backgroundImage: `url("../images/ic_pay_small.png")` }}
                ></div>
                <div className="overview-box">
                <div className="overview-subpoint">Women Safety</div>
                <p>Automatic send alert to police and your loved one.</p>
                </div>
            </ul>
            <ul className="overview-point">
                <div
                className="overview-image"
                style={{ backgroundImage: `url("../images/ic_frame_small.png")` }}
                ></div>
                <div className="overview-box">
                <div className="overview-subpoint">Quick help</div>
                <p>Instant help in emergency Medical conditions</p>
                </div>
            </ul>
            </div>
        </div>
      <Footer />
    </>
  );
}

export default Home;
